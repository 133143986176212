import {
    DeepLinkData,
    HashedEmailResp,
    IGeoData,
    SignUpData,
    UserInfoResponse,
    UserUpdateData,
} from 'types/user/userApiInterface';

import Base from './Base';

class UserApi extends Base {
    async getUser<Response = UserInfoResponse>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/');
    }

    async update<Data = UserUpdateData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.patch<Data, Response>('user/', payload);
    }

    async signUp<Data = SignUpData, Response = UserInfoResponse>(payload: Data): Promise<Partial<Response> | unknown> {
        // @ts-ignore
        return await this.apiClient.public.post<Data, Response>('sign-up/', {"name": "string",
            // @ts-ignore
            "email": payload.email,
            "gender": "female",
            "age": 16,
            "height": 122,
            "weight": 30,
            "default_map_type": "dark",
            "default_activity_type": "backpacking",
            "units": "imperial",
            // "profile_image_url": "string",
            "reasons": [
                "improve_physic"
            ],
            "goal": [
                "improve_fitness"
            ],
            "trail_level": "easy",
            "special": [
                "backpacking"
            ],
            "terrain": [
                "canyons"
            ],
            "safety": "more_less",
            "fitlevel": "advanced",
            "walking": "less1hour",
            "health": "no",
            "bmi_group": "normal",
            // "jws": "string"
        });
    }

    async deepLinks<Response = DeepLinkData>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/auth-deeplink');
    }

    async hashedEmail() {
        return await this.apiClient.private.get<HashedEmailResp>('front-hash');
    }

    async geoData<Response = IGeoData>(): Promise<Partial<Response> | unknown> {
        return await this.apiClient.private.get<Response>('user/geo-data');
    }
}

export default UserApi;
