import { InitPayPal } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { takeLatest, put } from 'redux-saga/effects';

import * as actionTypes from 'redux/payment/actionTypes';
import { setInitPaymentData, setOrderId } from 'redux/payment/actions';

import { PaymentData } from 'types/payments/paymentApiInterfaces';
import { ActionType } from 'types/commonInterfaces';
import api from "../../../apiSingleton";
import initPaypal from './payPal';

export function* init({ payload }: ActionType<InitPayPal>) {
    yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });

    try {
        // const response: PaymentData = yield Solid.init('payPal', payload);

        const response: PaymentData = yield api.payment.init(payload);

        const url = response.pay_form?.script_url;

        if (!url) {
            throw new Error('URL for PayPal script is missing');
        }

        const buttonWrapperDiv = document.getElementById('paypal-button');

        if (!url || !buttonWrapperDiv) {
            throw Error('url && buttonWrapper required for init PayPal button');
        }



        initPaypal(payload)

        yield put(setInitPaymentData(response));
        yield put(setOrderId(response?.order?.order_id));
    } catch (e) {
        console.error('Error init');
    }
}

export const initPayPal = [takeLatest(actionTypes.INIT_PAY_PAL, init)];
