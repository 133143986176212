import { PRODUCT_CODES } from 'constants/payments';

export const DEFAULT_FLOW_PROJECT_NAME = 'yoga_go';
export const DEFAULT_EXPIRE_CONFIG_TIME = 1440;
export const DEFAULT_FLOW_LINK = 'https://yoga-front-stage.asqq.io/';

export const DEFAULT_FLOW = {
    branch_name: 'default',
    flow_name: 'yoga_go__default__default9__Default',
    start_page: {
        id: 'index',
        name: 'index',
        config: {},
    },
    onboarding_flow: [
        {
            id: 'ob_social_proof',
            name: 'ob_social_proof',
            config: {},
        },
        {
            id: 'ob_goals',
            name: 'ob_goals',
            config: {},
        },
        {
            id: 'ob_current_body_type_a',
            name: 'ob_current_body_type_a_v',
            config: {
                femaleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_CF_regular-violet.png',
                femaleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_CF_flabby-violet.png',
                femaleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_CF_extra-violet.png',
                femaleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_CF_over-violet.png',
                femaleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_CF_obese-violet.png',
                maleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_CM_Skinny.png',
                maleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_CM_flabby.png',
                maleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_CM_Extra.png',
                maleOptionImage4: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_CM_over.png',
                maleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_CM_obese.png',
                ScreenTitle: 'bodytype',
            },
        },
        {
            id: 'ob_target_body_type_a',
            name: 'ob_target_body_type_a_v',
            config: {
                femaleOptionImage1:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_TF_regular-violet.png',
                femaleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_TF_fit-violet.png',
                femaleOptionImage3:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_TF_flat-violet.png',
                femaleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_TF_athletic-violet.png',
                femaleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_TF_curvy-violet.png',
                maleOptionImage1: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/1_TM_Cut.png',
                maleOptionImage2:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/2_TM_Shredded.png',
                maleOptionImage3: 'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/3_TM_bulk.png',
                maleOptionImage4:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/4_TM_Extra_Bulk.png',
                maleOptionImage5:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/5_TM_Muscle.png',
                ScreenTitle: 'targetbodytype',
            },
        },
        {
            id: 'ob_target_zones_c',
            name: 'ob_target_zones_c_prob',
            config: {
                ScreenTitle: 'problemareas',
            },
        },
        {
            id: 'ob_body_image',
            name: 'ob_body_image',
            config: {},
        },
        {
            id: 'ob_activity_level',
            name: 'ob_activity_level',
            config: {},
        },
        {
            id: 'ob_walking',
            name: 'ob_walking',
            config: {},
        },
        {
            id: 'ob_pushups_squats',
            name: 'ob_pushups_squats',
            config: {},
        },
        {
            id: 'ob_yoga_level',
            name: 'ob_yoga_level',
            config: {},
        },
        {
            id: 'ob_bending_knees',
            name: 'ob_bending_knees',
            config: {},
        },
        {
            id: 'ob_tags_a',
            name: 'ob_tags_a',
            config: {},
        },
        {
            id: 'ob_meals_feel',
            name: 'ob_meals_feel',
            config: {},
        },
        {
            id: 'ob_sleep',
            name: 'ob_sleep',
            config: {},
        },
        {
            id: 'ob_water',
            name: 'ob_water',
            config: {},
        },
        {
            id: 'ob_diets',
            name: 'ob_diets',
            config: {},
        },
        {
            id: 'ob_height',
            name: 'ob_height',
            config: {},
        },
        {
            id: 'ob_weight',
            name: 'ob_weight',
            config: {},
        },
        {
            id: 'ob_weight_target',
            name: 'ob_weight_target',
            config: {},
        },
        {
            id: 'ob_age',
            name: 'ob_age',
            config: {},
        },
        {
            id: 'feedback_summary_fitlevel',
            name: 'feedback_summary_fitlevel',
            config: {},
        },
        {
            id: 'ob_occasion',
            name: 'ob_occasion',
            config: {},
        },
        {
            id: 'ob_occasion_result',
            name: 'ob_occasion_result',
            config: {},
        },
        {
            id: 'creating',
            name: 'creating',
            config: {},
        },
        {
            id: 'email_account_a',
            name: 'email_account_a',
            config: {},
        },
        {
            id: 'email_a',
            name: 'email_a',
            config: {},
        },
        {
            id: 'email_consent',
            name: 'email_consent',
            config: {},
        },
        {
            id: 'result',
            name: 'result',
            config: {},
        },
    ],
    payment_flow: [
        {
            id: 'upsell_sub',
            name: 'upsell_sub_belly_7min',
            parent_id: 'offer_a6_golf5',
            is_paid: true,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'c41148e7-660f-43ab-8365-98e27569571d',
                    name: 'One-month intro (19.99) + One-month (19.99) main belly_7min',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.BELLY_SEVENMIN,
                    is_preselected: true,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 1999,
                    price: 1999,
                    start_price: 4000,
                    ltv: 3434,
                },
                {
                    payment_type: 'subscription',
                    id: 'fe9612ec-e4c5-4f36-a6b0-2a990b05b36e',
                    name: 'One-month intro (14.99) + One-month (14.99) main belly',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.BELLY,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 1499,
                    price: 1499,
                    start_price: 2200,
                    ltv: 2619,
                },
                {
                    payment_type: 'subscription',
                    id: '7c9341e9-1323-499b-a2c5-c25a267da638',
                    name: 'One-month intro (14.99) + One-month (14.99) main 7min',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 1499,
                    price: 1499,
                    start_price: 2200,
                    ltv: 2619,
                },
                {
                    payment_type: 'subscription',
                    id: 'ddad485c-da74-4b92-82c3-c30767d570a4',
                    name: 'One-month intro (12.99) + One-month (12.99) offer belly_7min',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.BELLY_SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 1299,
                    price: 1299,
                    start_price: 4000,
                    ltv: 2308,
                },
                {
                    payment_type: 'subscription',
                    id: 'b8dec267-32f2-498e-a4da-8c168f917c37',
                    name: 'One-month intro (9.99) + One-month (9.99) offer belly',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.BELLY,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    price: 999,
                    start_price: 2200,
                    ltv: 1804,
                },
                {
                    payment_type: 'subscription',
                    id: '8c06f861-bfd6-4c4e-903b-cdb971ad0163',
                    name: 'One-month intro (9.99) + One-month (9.99) offer 7min',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 999,
                    price: 999,
                    start_price: 2200,
                    ltv: 1804,
                },
                {
                    payment_type: 'subscription',
                    id: 'fe3167e0-84c5-406c-8047-f50c0e6f4126',
                    name: 'Three-months intro (39.99) + Three-months (39.99) main belly_7min',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.BELLY_SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 3999,
                    price: 3999,
                    start_price: 8000,
                    ltv: 4697,
                },
                {
                    payment_type: 'subscription',
                    id: '0e21d445-f469-416e-b16e-3d09e320e7cd',
                    name: 'Three-months intro (29.99) + Three-months (29.99) main belly',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.BELLY,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 2999,
                    price: 2999,
                    start_price: 4200,
                    ltv: 3633,
                },
                {
                    payment_type: 'subscription',
                    id: 'b03382b8-90c0-4976-b3c6-eaec632d09b6',
                    name: 'Three-months intro (29.99) + Three-months (29.99) main 7min',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 2999,
                    price: 2999,
                    start_price: 4200,
                    ltv: 3633,
                },
                {
                    payment_type: 'subscription',
                    id: '14e09204-dbd0-43d1-baa6-622a6a9e26ab',
                    name: 'Three-months intro (25.99) + Three-months (25.99) offer belly_7min',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.BELLY_SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 2599,
                    price: 2599,
                    start_price: 8000,
                    ltv: 3205,
                },
                {
                    payment_type: 'subscription',
                    id: '14d52f75-3fb5-49be-b9e1-1758dbb66157',
                    name: 'Three-months intro (20.99) + Three-months (20.99) offer belly',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.BELLY,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 2099,
                    price: 2099,
                    start_price: 4200,
                    ltv: 2635,
                },
                {
                    payment_type: 'subscription',
                    id: 'feb68607-3973-4c66-9421-6b326143e6a0',
                    name: 'Three-months intro (20.99) + Three-months (20.99) offer 7min',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.SEVENMIN,
                    is_preselected: false,
                    product_state: 'per_month',
                    start_price_per_state: 0,
                    price_per_state: 2099,
                    price: 2099,
                    start_price: 4200,
                    ltv: 2635,
                },
            ],
            config: {},
        },
        {
            id: 'offer_a6_golf5',
            name: 'offer_a6_golf5_p4_full_prices',
            parent_id: 'payment_g6_golf5',
            is_paid: false,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'ab90f9ca-d365-4468-887a-51b01201e78e',
                    name: 'One-week intro (5.50) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 150,
                    price_per_state: 79,
                    price: 550,
                    start_price: 1049,
                    ltv: 3475,
                },
                {
                    payment_type: 'subscription',
                    id: '3af9e081-b45d-401e-b858-c6a68e153467',
                    name: 'One-month intro (11.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: true,
                    product_state: 'per_day',
                    start_price_per_state: 100,
                    price_per_state: 39,
                    price: 1199,
                    start_price: 3099,
                    ltv: 3383,
                },
                {
                    payment_type: 'subscription',
                    id: 'eb667562-a647-4ee7-bd72-b591148dd0d9',
                    name: 'Three-month intro (21.49) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 59,
                    price_per_state: 24,
                    price: 2149,
                    start_price: 5319,
                    ltv: 4897,
                },
                {
                    payment_type: 'subscription',
                    id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
                    name: 'One-week intro (10.49) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 0,
                    price_per_state: 150,
                    price: 1049,
                    start_price: 1049,
                    ltv: 3841,
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 0,
                    price_per_state: 100,
                    price: 3099,
                    start_price: 3099,
                    ltv: 5710,
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 0,
                    price_per_state: 59,
                    price: 5319,
                    start_price: 5319,
                    ltv: 7810,
                },
            ],
            config: {
                FullPrices: 'on',
            },
        },
        // {
        //     id: 'upsell_sub',
        //     name: 'upsell_sub_belly_7min',
        //     parent_id: 'payment_g6_golf5',
        //     is_paid: true,
        //     skip_checkout: false,
        //     products: [
        //         {
        //             payment_type: 'subscription',
        //             id: 'c41148e7-660f-43ab-8365-98e27569571d',
        //             name: 'One-month intro (19.99) + One-month (19.99) main belly_7min',
        //             trial: 31,
        //             period: 31,
        //             product_code: PRODUCT_CODES.BELLY_SEVENMIN,
        //             is_preselected: true,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 1999,
        //             price: 1999,
        //             start_price: 4000,
        //             ltv: 3434,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: 'fe9612ec-e4c5-4f36-a6b0-2a990b05b36e',
        //             name: 'One-month intro (14.99) + One-month (14.99) main belly',
        //             trial: 31,
        //             period: 31,
        //             product_code: PRODUCT_CODES.BELLY,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 1499,
        //             price: 1499,
        //             start_price: 2200,
        //             ltv: 2619,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: '7c9341e9-1323-499b-a2c5-c25a267da638',
        //             name: 'One-month intro (14.99) + One-month (14.99) main 7min',
        //             trial: 31,
        //             period: 31,
        //             product_code: PRODUCT_CODES.SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 1499,
        //             price: 1499,
        //             start_price: 2200,
        //             ltv: 2619,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: 'ddad485c-da74-4b92-82c3-c30767d570a4',
        //             name: 'One-month intro (12.99) + One-month (12.99) offer belly_7min',
        //             trial: 31,
        //             period: 31,
        //             product_code: PRODUCT_CODES.BELLY_SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 1299,
        //             price: 1299,
        //             start_price: 4000,
        //             ltv: 2308,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: 'b8dec267-32f2-498e-a4da-8c168f917c37',
        //             name: 'One-month intro (9.99) + One-month (9.99) offer belly',
        //             trial: 31,
        //             period: 31,
        //             product_code: PRODUCT_CODES.BELLY,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 999,
        //             price: 999,
        //             start_price: 2200,
        //             ltv: 1804,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: '8c06f861-bfd6-4c4e-903b-cdb971ad0163',
        //             name: 'One-month intro (9.99) + One-month (9.99) offer 7min',
        //             trial: 31,
        //             period: 31,
        //             product_code: PRODUCT_CODES.SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 999,
        //             price: 999,
        //             start_price: 2200,
        //             ltv: 1804,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: 'fe3167e0-84c5-406c-8047-f50c0e6f4126',
        //             name: 'Three-months intro (39.99) + Three-months (39.99) main belly_7min',
        //             trial: 90,
        //             period: 90,
        //             product_code: PRODUCT_CODES.BELLY_SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 3999,
        //             price: 3999,
        //             start_price: 8000,
        //             ltv: 4697,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: '0e21d445-f469-416e-b16e-3d09e320e7cd',
        //             name: 'Three-months intro (29.99) + Three-months (29.99) main belly',
        //             trial: 90,
        //             period: 90,
        //             product_code: PRODUCT_CODES.BELLY,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 2999,
        //             price: 2999,
        //             start_price: 4200,
        //             ltv: 3633,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: 'b03382b8-90c0-4976-b3c6-eaec632d09b6',
        //             name: 'Three-months intro (29.99) + Three-months (29.99) main 7min',
        //             trial: 90,
        //             period: 90,
        //             product_code: PRODUCT_CODES.SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 2999,
        //             price: 2999,
        //             start_price: 4200,
        //             ltv: 3633,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: '14e09204-dbd0-43d1-baa6-622a6a9e26ab',
        //             name: 'Three-months intro (25.99) + Three-months (25.99) offer belly_7min',
        //             trial: 90,
        //             period: 90,
        //             product_code: PRODUCT_CODES.BELLY_SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 2599,
        //             price: 2599,
        //             start_price: 8000,
        //             ltv: 3205,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: '14d52f75-3fb5-49be-b9e1-1758dbb66157',
        //             name: 'Three-months intro (20.99) + Three-months (20.99) offer belly',
        //             trial: 90,
        //             period: 90,
        //             product_code: PRODUCT_CODES.BELLY,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 2099,
        //             price: 2099,
        //             start_price: 4200,
        //             ltv: 2635,
        //         },
        //         {
        //             payment_type: 'subscription',
        //             id: 'feb68607-3973-4c66-9421-6b326143e6a0',
        //             name: 'Three-months intro (20.99) + Three-months (20.99) offer 7min',
        //             trial: 90,
        //             period: 90,
        //             product_code: PRODUCT_CODES.SEVENMIN,
        //             is_preselected: false,
        //             product_state: 'per_month',
        //             start_price_per_state: 0,
        //             price_per_state: 2099,
        //             price: 2099,
        //             start_price: 4200,
        //             ltv: 2635,
        //         },
        //     ],
        //     config: {},
        // },
        {
            id: 'payment_g6_golf5',
            name: 'payment_g6_golf5_p4_default_2d_full',
            parent_id: null,
            is_paid: null,
            skip_checkout: false,
            products: [
                {
                    payment_type: 'subscription',
                    id: 'c714c542-4373-41c9-b247-209815223772',
                    name: 'One-week intro (6.93) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 150,
                    price_per_state: 99,
                    price: 693,
                    start_price: 1049,
                    ltv: 3466,
                },
                {
                    payment_type: 'subscription',
                    id: '9ea6bce5-34fe-4ee3-93d9-4ea5f455bc37',
                    name: 'One-month intro (15.19) + One-month (30.99)\t',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: true,
                    product_state: 'per_day',
                    start_price_per_state: 100,
                    price_per_state: 49,
                    price: 1519,
                    start_price: 3099,
                    ltv: 4528,
                },
                {
                    payment_type: 'subscription',
                    id: '7ad6132e-71fc-47c9-9e82-dcea25af4ac3',
                    name: 'Three-month intro (25.99) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 59,
                    price_per_state: 29,
                    price: 2599,
                    start_price: 5319,
                    ltv: 6203,
                },
                {
                    payment_type: 'subscription',
                    id: 'f9b1784e-2f7c-434d-90ce-8b2adfa65e68',
                    name: 'One-week intro (10.49) + One-month (30.99)',
                    trial: 7,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 0,
                    price_per_state: 150,
                    price: 1049,
                    start_price: 1049,
                    ltv: 3841,
                },
                {
                    payment_type: 'subscription',
                    id: 'ee4ac51e-17c6-4e61-a565-8c5a39f15bdd',
                    name: 'One-month intro (30.99) + One-month (30.99)',
                    trial: 31,
                    period: 31,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 0,
                    price_per_state: 100,
                    price: 3099,
                    start_price: 3099,
                    ltv: 5710,
                },
                {
                    payment_type: 'subscription',
                    id: 'f76ef55c-8026-422a-bbc9-c2b5d0442670',
                    name: 'Three-month intro (53.19) + Three-month (53.19)',
                    trial: 90,
                    period: 90,
                    product_code: PRODUCT_CODES.MAIN,
                    is_preselected: false,
                    product_state: 'per_day',
                    start_price_per_state: 0,
                    price_per_state: 59,
                    price: 5319,
                    start_price: 5319,
                    ltv: 7810,
                },
            ],
            config: {
                ScreenTitle: 'default',
                StatBadgeLevel: 'default',
                FullPrices: 'on',
                femaleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/female_before_default.png',
                femaleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/female_after_default.png',
                maleModelImageBefore:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/male_before_payment.png',
                maleModelImageAfter:
                    'https://web.appscdn.io/web/WebDivisionFiles/Public/YG(w)/monetisation/male_after_payment.png',
            },
        },
    ],
    expire: 1699440731316,
};
