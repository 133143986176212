import { InitPaymentGenerator } from 'solid-payment/src/interfaces/payment';
import Solid from 'solid-payment';
import { takeLatest, put } from 'redux-saga/effects';
import { InitConfig } from '@solidgate/client-sdk-loader';

import * as actionTypes from 'redux/payment/actionTypes';
import { setMerchantData } from 'redux/payment/actions';

import { ActionType } from 'types/commonInterfaces';
import {MerchantDataResponseType} from "../../../types/payments/paymentApiInterfaces";
import api from "../../../apiSingleton";

function* init({ payload }: ActionType<InitPaymentGenerator>) {
    try {
        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: true });
        const merchantData: InitConfig['merchantData'] = yield api.payment.getMerchantData(payload);

        yield put({ type: actionTypes.SET_LOADING_STATUS, payload: false });
        yield put(setMerchantData(merchantData));
    } catch (e) {
        console.error('Init payment error', e);
    }
}

export const initPaymentGenerator = [takeLatest(actionTypes.INIT_PAYMENT_GENERATOR, init)];
