import Solid from 'solid-payment';
import { select, take, call, put } from 'redux-saga/effects';

import config from 'config';

import { selectToken } from 'redux/user/selectors';
import { RootState } from 'redux/types';
import { selectSolidInited } from 'redux/payment/selectors';
import { setSolidInited } from 'redux/payment/actions';

import { getLanguage } from 'helpers/utils';

/*
 * Selector. The query depends by the state shape
 */
export const getToken = (state: RootState) => selectToken(state);
export const getSolidInit = (state: RootState) => selectSolidInited(state);

export function* init() {
    try {
        Solid.setHeaders({
            apiUrl: config.API_URL,
            apiKey: config.API_KEY,
            apiVersion: config.API_VERSION,
            language: yield select(getLanguage),
            token: yield select(getToken),
        });
        console.info('%cinit - SOLID PAYMENT', 'color:green');

        yield put(setSolidInited(true));
    } catch (e) {
        console.log({e123123: e});
        console.error('Error', e);
    }
}

export function* tokenWatcher() {
    let previous: string = yield select(getToken);

    while (true) {
        const solidStatus: boolean = yield select(getSolidInit);

        yield take('*'); // listening all actions
        const next: string = yield select(getToken);

        if ((!solidStatus && next) || (next !== previous && solidStatus)) {
            previous = next;
            yield call(init);
        }
    }
}

export const initSolidPayment = [tokenWatcher()];
